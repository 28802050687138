<template>
    <client-page class="wrap--login">
        <v-container>

            <div class="tit-wrap text-center">
                <h2 class="tit tit--lg">
                    회원가입
                </h2>
            </div>

            <join-form :code="code" :skin="code" />

        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import JoinForm from "@/components/client/join/join-form.vue";
import { USER_TYPES } from "@/assets/variables";

export default {
    components: {
        ClientPage,
        JoinForm,
    },
    computed: {
        code() {
            return this.$route.query?.code || USER_TYPES.PERSON.value;
        },
    },
};
</script>
