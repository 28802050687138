<template>
    <div class="page-section">

        <v-row>
            <v-spacer />
            <v-col cols="auto">
                <v-checkbox :value="termsList.length == selected.length" @click="selected = termsList.length == selected.length ? [] : termsList.map((terms) => terms._id)" label="전체 이용약관에 동의합니다." hide-details class="font-weight-medium" />
            </v-col>
        </v-row>

        <v-divider class="mt-8 mt-lg-20 mb-20 mb-lg-40" />

        <div class="agreements">
            <div v-for="terms in termsList" :key="terms._id" class="agreement">
                <v-row align-sm="center" justify-sm="space-between">
                    <v-col cols="12" sm="" class="mb-10 mb-sm-0">
                        <h3 class="font-size-20 font-size-lg-28">
                            {{ terms.subject }}
                        </h3>
                    </v-col>
                    <v-col cols="12" sm="auto" class="d-flex d-sm-block justify-end">
                        <v-checkbox v-model="selected" name="terms" :value="terms._id" label="약관에 동의합니다." hide-details/>
                    </v-col>
                </v-row>
                <v-divider class="border-2 primary mt-8 mt-lg-20 mb-4 mb-lg-16" />
                <div v-if="terms.content" class="agreement-box">
                    <p name="terms" readonly v-html="terms.content" class="page-text page-text--sm grey-9--text"></p>
                </div>
            </div>
        </div>


        <!-- <v-divider class="primary"></v-divider>
        <div class="py-20 px-lg-20">
            <v-row>
                <v-col cols="12" md="9">
                    <v-checkbox :value="termsList.length == selected.length" @click="selected = termsList.length == selected.length ? [] : termsList.map((terms) => terms._id)" label="전체 이용약관에 동의합니다." hide-details class="d-inline-flex"></v-checkbox>
                </v-col>
            </v-row>
        </div>
        <v-divider class="primary"></v-divider>
        <div class="join-row-wrap" v-for="terms in termsList" :key="terms._id">
            <v-row>
                <v-col cols="12" md="3">
                    <h2 class="font-size-18 font-size-md-20 font-weight-medium">{{ terms.subject }}</h2>
                </v-col>
                <v-col cols="12" md="9">
                    <v-card tile outlined elevation="0" height="180" class="join-box">
                        <v-card-text>
                            <div v-if="terms.content">
                                <p class="textarea" name="terms" readonly v-html="terms.content"></p>
                            </div>
                        </v-card-text>
                    </v-card>
                    <div class="mt-10 mt-md-16">
                        <v-checkbox v-model="selected" name="terms" :value="terms._id" label="약관에 동의합니다." hide-details class="d-inline-flex"></v-checkbox>
                    </div>
                </v-col>
            </v-row>
        </div> -->

    </div>
</template>

<script>
import api from "@/api";
export default {
    props: ["code"],
    data() {
        return {
            selected: [],
            termsList: [],

            filter: {
                code: this.$props.code || undefined,
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { termsList } = await api.v1.terms.gets({ params: this.filter });
                this.termsList = termsList;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        check() {
            try {
                this.termsList.forEach((terms) => {
                    if (terms.necessary && this.selected.indexOf(terms._id) < 0) {
                        throw new Error(`[${terms.subject}]에 동의해 주세요`);
                    }
                });

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.agreements{
    .agreement:not(:last-child){
        margin-bottom: 20px;
    }
}
.agreement-box{
    background-color: #fafafa;
    border: 1px solid #ececec;
    border-radius: 6px;
    padding: 20px;
    height: 300px;
    overflow-y: auto;
    overflow-x: visible;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .agreements{
        .agreement:not(:last-child){
            margin-bottom: 40px;
        }
    }
}
@media (min-width:1200px){
}

</style>
