var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-section"
  }, [_c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "font-weight-medium",
    attrs: {
      "value": _vm.termsList.length == _vm.selected.length,
      "label": "전체 이용약관에 동의합니다.",
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        _vm.selected = _vm.termsList.length == _vm.selected.length ? [] : _vm.termsList.map(function (terms) {
          return terms._id;
        });
      }
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mt-8 mt-lg-20 mb-20 mb-lg-40"
  }), _c('div', {
    staticClass: "agreements"
  }, _vm._l(_vm.termsList, function (terms) {
    return _c('div', {
      key: terms._id,
      staticClass: "agreement"
    }, [_c('v-row', {
      attrs: {
        "align-sm": "center",
        "justify-sm": "space-between"
      }
    }, [_c('v-col', {
      staticClass: "mb-10 mb-sm-0",
      attrs: {
        "cols": "12",
        "sm": ""
      }
    }, [_c('h3', {
      staticClass: "font-size-20 font-size-lg-28"
    }, [_vm._v(" " + _vm._s(terms.subject) + " ")])]), _c('v-col', {
      staticClass: "d-flex d-sm-block justify-end",
      attrs: {
        "cols": "12",
        "sm": "auto"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "name": "terms",
        "value": terms._id,
        "label": "약관에 동의합니다.",
        "hide-details": ""
      },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    })], 1)], 1), _c('v-divider', {
      staticClass: "border-2 primary mt-8 mt-lg-20 mb-4 mb-lg-16"
    }), terms.content ? _c('div', {
      staticClass: "agreement-box"
    }, [_c('p', {
      staticClass: "page-text page-text--sm grey-9--text",
      attrs: {
        "name": "terms",
        "readonly": ""
      },
      domProps: {
        "innerHTML": _vm._s(terms.content)
      }
    })]) : _vm._e()], 1);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }