var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--login"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(" 회원가입 ")])]), _c('terms-of-agreements', {
    ref: "terms",
    attrs: {
      "code": _vm.$route.query.code
    }
  }), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "outlined": "",
      "color": "grey-b3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("취소")])]), _c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" 동의하기 ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }