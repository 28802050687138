<template>
    <client-page class="wrap--login">
        <v-container>

            <div class="tit-wrap text-center">
                <h2 class="tit tit--lg">
                    회원가입
                </h2>
            </div>

            <terms-of-agreements ref="terms" :code="$route.query.code" />

            <div class="v-btn--group">
                <v-btn @click="$router.go(-1)" outlined color="grey-b3" class="min-w-120px min-w-lg-160px">
                    <span class="grey-6--text">취소</span>
                </v-btn>
                <v-btn @click="submit" color="primary" class="min-w-120px min-w-lg-160px">
                    동의하기
                </v-btn>
            </div>

        </v-container>

        <!-- <div class="container">
            <h2 class="join-title">회원가입</h2>

            <div class="join-container">

                <nav class="join-step">
                    <ul class="join-step-lists">
                        <li class="list list--on">
                            <span class="badge">STEP 1</span >
                            <span class="text">약관동의</span>
                        </li>
                        <li class="list">
                            <span class="badge">STEP 2</span>
                            <span class="text">정보입력</span>
                        </li>
                        <li class="list">
                            <span class="badge">STEP 3</span>
                            <span class="text">가입완료</span>
                        </li>
                    </ul>
                </nav>

                <terms-of-agreements ref="terms" :code="$route.query.code" />

                <div class="bottom-button">
                    <div class="v-btn--group v-btn--group--large">
                        <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">취소</span></v-btn>
                        <v-btn x-large color="primary" class="w-100 mw-220px" @click="submit">동의하기</v-btn>
                    </div>
                </div>
            </div>
        </div> -->
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import TermsOfAgreements from "@/components/client/join/terms-of-agreements.vue";

export default {
    components: {
        ClientPage,
        TermsOfAgreements,
    },
    methods: {
        submit() {
            try {
                if (this.$refs.terms.check()) {
                    this.$store.dispatch("agreements", this.$refs.terms.selected);

                    this.$router.push({
                        path: "/join/form",
                        query: this.$route.query,
                    });
                }
            } catch (error) {
                alert(error.message.toString());
            }
        },
    },
};
</script>
