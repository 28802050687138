var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section section--last"
  }, [_c('v-container', [_c('div', {
    staticClass: "d-flex flex-column align-center justify-center"
  }, [_c('i', {
    staticClass: "icon icon-check mb-12 mb-lg-24"
  }), _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(" 회원가입이 완료되었습니다. ")])]), _c('v-btn', {
    staticClass: "min-w-lg-200px",
    attrs: {
      "large": "",
      "color": "primary",
      "to": "/content/test?type=test"
    }
  }, [_vm._v("중독테스트 하러가기")])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }