var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--login"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(" 회원가입 ")])]), _c('join-form', {
    attrs: {
      "code": _vm.code,
      "skin": _vm.code
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }