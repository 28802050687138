<template>
    <client-page>
        <section class="section section--last">
            <v-container>

                <div class="d-flex flex-column align-center justify-center">
                
                    <i class="icon icon-check mb-12 mb-lg-24"></i>

                    <div class="tit-wrap text-center">
                        <h2 class="tit tit--lg">
                            회원가입이 완료되었습니다.
                        </h2>
                    </div>

                    <v-btn large color="primary" to="/content/test?type=test" class="min-w-lg-200px">중독테스트 하러가기</v-btn>

                </div>

                <!-- <div class="join-container">
                    <div class="join-complate">
                        <i class="icon icon-handshake"></i>
                        <h3 class="join-complate__title">회원가입이 완료 되었습니다.</h3>
                    </div>

                    <div class="bottom-button">
                        <div class="v-btn--group v-btn--group--large">
                            <v-btn x-large color="primary" to="/content/test?type=test" class="w-100 mw-220px">중독테스트 하러가기</v-btn>
                        </div>
                    </div>
                </div> -->

            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default {
    components: {
        ClientPage,
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function() {},
    },
};
</script>
